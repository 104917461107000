import React from 'react'
import { Link, graphql } from 'gatsby'
import { Layout, SEO } from '@components'
import './blog-post.scss'

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const { previous } = pageContext

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article>
        {/* {post.frontmatter.category && (
          <header className="push--bottom txt-center ">
            <h4 className="txt-accent txt-small txt-heavy">
              {post.frontmatter.category}
            </h4>
          </header>
        )} */}
        <h1 className="push-small--bottom txt-center ">
          {post.frontmatter.title}
        </h1>
        <section dangerouslySetInnerHTML={{ __html: post.html }} />
      </article>
      <footer
        className="push--bottom"
        style={{
          display: `flex`,
          justifyContent: `space-between`,
          listStyle: `none`,
          padding: 0,
        }}
      >
        <div>
          <h3 className="txt-small txt-heavy">Last Updated</h3>
          <time className="txt-neutral txt-small">{post.frontmatter.date}</time>
        </div>
        {/* {previous && (
          <div style={{maxWidth: '50%'}}>
            <h3 className="txt-small txt-heavy">Next</h3>
            <Link to={previous.fields.slug} rel="previous">
              {previous.frontmatter.title}
            </Link>
            &nbsp;→
          </div>
        )} */}
      </footer>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
      }
    }
  }
`
